import { UnknownObject } from '@/src/common/types';
import { isProdReleaseEnv } from '@/src/common/utils/environment';
import { gtm, sentry } from './thirdParty';

export type TrackingExceptionErrorType = sentry.ExceptionErrorTypes;
export type TrackingMessageErrorType = sentry.MessageErrorTypes;

const trafficType = isProdReleaseEnv ? 'production' : 'internal';

export const trackingEvent = (trackingData: UnknownObject): void => {
  gtm.sendEventGtm({
    ...trackingData,
    traffic_type: trafficType,
  });
};

export const trackingPageView = (trackingData: UnknownObject): void => {
  gtm.sendEventGtm({
    event: 'page_view',
    ...trackingData,
    traffic_type: trafficType,
  });
};

export const trackingPageInfos = (
  pageName: string,
  trackingData: UnknownObject,
): void => {
  gtm.pushPageInfos(pageName, { ...trackingData });
};

export const trackingExceptionError = (
  trackingErrorData: TrackingExceptionErrorType,
): void => {
  sentry.sentryExceptionError(trackingErrorData);
};

export const trackingMessageError = (
  trackingErrorData: TrackingMessageErrorType,
): void => {
  sentry.sentryMessageError(trackingErrorData);
};
