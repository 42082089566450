import { Minus, Plus } from '@grupo-sbf/motriz-icons';
import { Divider, Typography } from '@grupo-sbf/motriz-nike';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';
import SectionLink from '@/src/common/components/SectionLink';
import { variant } from '@/src/common/utils/styles';

export const Container = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.color.neutral[700]};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 1280px;
    padding: ${theme.spacing[400]} ${theme.spacing[300]};

    ${media.greaterThan('large')} {
      padding: ${theme.spacing[600]};
    }
  `}
`;

export const MainFooter = styled.div`
  ${({ theme }) => css`
    ${media.greaterThan('large')} {
      display: grid;
      grid-template-columns: repeat(4, 228px);
      grid-gap: ${theme.spacing[300]};
      justify-content: space-between;
      align-items: flex-start;
    }
  `}
`;

type VerticalGridProps = {
  size: 'small' | 'large';
};

export const VerticalGrid = styled.div<VerticalGridProps>`
  ${({ theme, size }) => css`
    display: grid;
    grid-gap: ${variant(size, {
      small: theme.spacing[100],
      large: theme.spacing[200],
    })};

    ${media.greaterThan('small')} {
      margin-bottom: ${theme.spacing[300]};
    }

    a {
      color: ${theme.color.neutral[100]};
      justify-content: left;
    }
  `}
`;

export const StyledSectionLink = styled(SectionLink)`
  ${({ theme }) => css`
    text-transform: none;
    font-size: ${theme.fontSize.xsmall};
  `}
`;

// TODO: some section titles are not links, because
// of that I had to duplicate SectionLink component behavior
//
// Maybe we need the SectionLink component to be more generic
// to the point where it doesn't return a link
export const SectionTitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.xsmall};
    color: ${theme.color.neutral[200]};
    line-height: ${theme.lineHeight.normal};
    text-transform: uppercase;
    padding-bottom: ${theme.spacing[300]};
  `}
`;

export const AccordionTitle = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'small',
})`
  ${({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.color.neutral[100]};
  `}
`;

export const ExpandedIcon = styled(Minus)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
  `}
`;

export const ClosedIcon = styled(Plus)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
  `}
`;

export const CustomDivider = styled(Divider).attrs({
  mobileMargin: { m: [400, 0] },
  desktopMargin: { m: [400, 0] },
  variant: 'secondary',
  type: 'soft',
  orientation: 'horizontal',
})``;

export const SubFooter = styled.div`
  display: grid;
  place-items: center;
  ${media.greaterThan('large')} {
    grid-template-columns: 330px 1fr;
    grid-gap: 150px;
  }
`;

export const SubFooterLinks = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 330px;
    display: flex;
    justify-content: space-between;

    a {
      color: ${theme.color.neutral[100]};
    }
  `}
`;

export const Copyright = styled(Typography).attrs({
  type: 'simple',
  size: 'small',
})`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.color.neutral[300]};
    margin-top: ${theme.spacing[300]};

    ${media.greaterThan('large')} {
      text-align: right;
      margin: 0;
    }
  `}
`;
