import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

type WrapperProps = {
  maxWidth?: string;
};

type StyledContainerProps = {
  as?: React.ElementType;
  className?: string;
  fullHeight?: boolean;
};

export const StyledContainer = styled.div<StyledContainerProps>`
  ${({ fullHeight }) => css`
    display: flex;
    justify-content: center;
    height: auto;
    min-height: ${fullHeight ? '100vh' : 'auto'};
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, maxWidth }) => css`
    width: 100%;
    max-width: ${maxWidth || '1440px'};

    ${media.greaterThan('large')} {
      padding: ${theme.spacing[0]} ${theme.spacing[600]};
    }
  `}
`;
