import Link from 'next/link';
import { Ref, forwardRef } from 'react';
import { SectionLink as StyledSectionLink } from './SectionLink.styled';
import { SectionLinkProps } from './SectionLink.types';

const SectionLink = forwardRef(
  ({ href, text, ...props }: SectionLinkProps, ref: Ref<HTMLAnchorElement>) => (
    <Link href={href} passHref>
      <StyledSectionLink aria-label={text} {...props} ref={ref}>
        {text}
      </StyledSectionLink>
    </Link>
  ),
);

SectionLink.displayName = 'SectionLink';

export default SectionLink;
