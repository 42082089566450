import { Button as MotrizButton } from '@grupo-sbf/motriz-nike';
import { ButtonHTMLAttributes, ReactNode } from 'react';

type ButtonProps = {
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | undefined;
  onClick?: () => void;
  size?: 'small' | 'medium' | undefined;
  as?: 'button' | 'a';
  fullWidth?: boolean;
  hasBackground?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <MotrizButton
      {...props}
      style={{
        paddingTop: '3px',
      }}
    >
      {children}
    </MotrizButton>
  );
};

export default Button;
