import { SocialProfileJsonLd } from 'next-seo';

const SocialProfileJson = () => {
  return (
    <SocialProfileJsonLd
      type="Organization"
      name="Nike.com"
      url="https://nike.com.br"
      sameAs={[
        'https://www.facebook.com/nike',
        'https://www.youtube.com/user/nike',
        'https://www.instagram.com/nike/',
        'https://www.twitter.com/Nike/',
      ]}
    />
  );
};

export default SocialProfileJson;
