import { getCoreConfig } from '../../config';

/**
 * Returns if we are running server-side
 *
 * You should avoid using this function and
 * only use it in places where it is strictly necessary
 */
export const isServerSide = () => typeof window === 'undefined';

/**
 * Returns if we are running client-side
 *
 * You should avoid using this function and
 * only use it in places where it is strictly necessary
 */
export const isClientSide = () => typeof window !== 'undefined';

/**
 * Returns if we are running environment test
 *
 */
export const isTestEnv = process.env.NODE_ENV === 'test';

/**
 * Returns if we are running environment dev ( not build)
 *
 */
export const isDevEnv = process.env.NODE_ENV !== 'production';

/**
 * Returns if we are running environment production (afterbuild)
 *
 */
export const isProdEnv = process.env.NODE_ENV === 'production';

/**
 * Returns if we are running environment release production (nike.com.br)
 *
 */

export const isProdReleaseEnv =
  isProdEnv && process.env.NEXT_PUBLIC_STORE_ENV === 'production';

export const isProductionDomainEnv = () => {
  if (isServerSide()) {
    return false;
  }
  const {
    publicRuntimeConfig: { domain },
  } = getCoreConfig();
  const { host } = window.location;
  // TODO: Adicionar chave domain no secrets
  const domainStore = new URL(domain as string).hostname.replace('www.', '');
  return host === domainStore || host === `www.${domainStore}`;
};
