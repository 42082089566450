import Image, { ImgProps } from '@/src/common/components/Img';
import { GetObjSameKeys } from '@/src/common/utils/types';

type srcImgPropsType = Pick<ImgProps, 'src'>;
type srcProductImageType = { src: undefined };

type srcImgType = GetObjSameKeys<srcImgPropsType, srcProductImageType>;

type StaticImageProps = ImgProps &
  srcImgType & {
    disablePlaceholder?: boolean;
  };

const StaticImage = ({
  src,
  alt,
  width,
  height,
  disablePlaceholder = false,
  ...props
}: StaticImageProps) => {
  if (disablePlaceholder) {
    return (
      <Image src={src} alt={alt} width={width} height={height} {...props} />
    );
  }

  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      placeholder="blur"
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPstAcAAVcAyrKrMM4AAAAASUVORK5CYII="
      {...props}
    />
  );
};

export default StaticImage;
