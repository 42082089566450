import { Minus, Plus } from '@grupo-sbf/motriz-icons';
import { Typography } from '@grupo-sbf/motriz-nike';
import styled, { css } from 'styled-components';

export const AccordionTitle = styled(Typography).attrs({
  type: 'simple',
  size: 'small',
})`
  ${({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.color.neutral[100]};
  `}
`;

export const ExpandedIcon = styled(Minus)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
  `}
`;

export const ClosedIcon = styled(Plus)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
  `}
`;
