/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/nextjs';
import { Scope, SeverityLevel } from '@sentry/types';
import { ClientError } from '@/src/common/services/api/types';
import { UnknownObject } from '@/src/common/types';

export type ErrorDataTypes = {
  flow: string;
  checkpoint: string;
  namespace: string;
  level?: SeverityLevel;
  extraDetails?: UnknownObject;
};

export type ExceptionErrorTypes = ErrorDataTypes & {
  error: ClientError;
};

export type MessageErrorTypes = ErrorDataTypes & {
  message: string;
};

export type ErrorsDetailsTypes = ErrorDataTypes & {
  env: string;
  errorMessage: string;
};

export type ErrorsInfosTypes = {
  errorDetails: ErrorsDetailsTypes;
  scope: Scope;
};

const setErrorsInfo = ({ errorDetails, scope }: ErrorsInfosTypes) => {
  const {
    flow,
    checkpoint,
    namespace,
    env,
    errorMessage,
    level = 'error',
    extraDetails,
  } = errorDetails;
  scope.setTag('flow', flow);
  scope.setTag('checkpoint', checkpoint);
  scope.setTag('namespace', namespace);
  scope.setLevel(level);
  scope.setFingerprint([flow, checkpoint, namespace, env, errorMessage]);
  if (extraDetails) {
    Object.keys(extraDetails).forEach((key) => {
      scope.setExtra(key, extraDetails[key]);
    });
  }
};

export const sentryExceptionError = ({
  error,
  flow,
  checkpoint,
  namespace,
  level = 'error',
  extraDetails,
}: ExceptionErrorTypes) => {
  const errorMessage = error?.message;
  const errorDetails = {
    flow,
    checkpoint,
    namespace,
    env: process.env.NODE_ENV,
    errorMessage,
    level,
    extraDetails,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Sentry.captureException(error, (scope): any => {
    setErrorsInfo({
      errorDetails,
      scope,
    });
  });
};

export const sentryMessageError = ({
  message,
  flow,
  checkpoint,
  namespace,
  level = 'error',
  extraDetails,
}: MessageErrorTypes) => {
  const errorDetails = {
    flow,
    checkpoint,
    namespace,
    env: process.env.NODE_ENV,
    errorMessage: message,
    level,
    extraDetails,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Sentry.captureMessage(message, (scope): any => {
    setErrorsInfo({
      errorDetails,
      scope,
    });
  });
};
