import { ReactNode } from 'react';
import Container from '@/src/common/components/Container';
import Footer from '@/src/common/patterns/Footer';
import Header from '@/src/common/patterns/Header';

type MainLayoutPropsTypes = {
  children: ReactNode;
  fullHeight?: boolean;
  maxWidth?: string;
};

const MainLayout = ({
  fullHeight = true,
  children,
  maxWidth,
}: MainLayoutPropsTypes) => {
  return (
    <>
      <Header />
      <Container fullHeight={fullHeight} maxWidth={maxWidth || '1920rem'}>
        {children}
      </Container>
      <Footer />
    </>
  );
};

export default MainLayout;
