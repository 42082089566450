import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const PaymentsWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing[500]};

    ${media.greaterThan('large')} {
      margin-top: ${theme.spacing[600]};
    }
  `}
`;

export const PaymentsGrid = styled.div`
  ${({ theme }) => css`
    width: 200px;
    display: grid;
    grid-gap: ${theme.spacing[100]};
    grid-template-columns: repeat(auto-fit, 43px);
    justify-content: start;
  `}
`;

// TODO: the gradient must be a token
export const PaymentMethod = styled.div`
  ${({ theme }) => css`
    width: 43px;
    height: 32px;
    background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
    border-radius: ${theme.borderRadius.small};
    display: grid;
    place-content: center;
  `}
`;
