import { ReactNode, createContext, useContext, useState } from 'react';

type ObjectData = {
  product: {
    orderItemId: number;
    name: string;
    sku: string;
    imageSource: string;
    color: string;
    size: string;
    valueRefund: number;
    chargeback: boolean;
    exchangeVoucher: boolean;
    isPixRefund: boolean;
    requiresBankDetails: boolean;
    returnReasons: {
      id: number;
      description: string;
    }[];
  };
  motive: {
    description: string;
    motiveCode: string;
  };
  comment: string;
  bankAccountForRefund: {
    bankId: number;
    accountNumber: string;
    accountType: string;
    branch: string;
    accountOwnerName: string;
    cpfCnpj: string;
  };
  refundType: string;
};

type DevolutionContextData = {
  devolutionValues: ObjectData;
  setDevolutionPartialValues: (values: Partial<ObjectData>) => void;
};

export type DevolutionProviderProps = {
  children: ReactNode;
};

const emptyReturnReasons: {
  id: number;
  description: string;
}[] = [];

export const initialState = {
  product: {
    orderItemId: 0,
    name: '',
    sku: '',
    imageSource: '',
    color: '',
    size: '',
    valueRefund: 0,
    chargeback: false,
    exchangeVoucher: false,
    isPixRefund: false,
    requiresBankDetails: false,
    returnReasons: emptyReturnReasons,
  },
  motive: {
    description: '',
    motiveCode: '',
  },

  comment: '',
  bankAccountForRefund: {
    bankId: 0,
    accountNumber: '',
    accountType: '',
    branch: '',
    accountOwnerName: '',
    cpfCnpj: '',
  },
  refundType: '',
};

export const DevolutionContext = createContext({
  devolutionValues: initialState,
} as DevolutionContextData);

function DevolutionProvider({ children }: DevolutionProviderProps) {
  const [devolutionValues, setDevolutionValues] = useState(initialState);
  const setDevolutionPartialValues: DevolutionContextData['setDevolutionPartialValues'] =
    (values) => {
      setDevolutionValues({ ...devolutionValues, ...values });
    };

  return (
    <DevolutionContext.Provider
      value={{
        devolutionValues,
        setDevolutionPartialValues,
      }}
    >
      {children}
    </DevolutionContext.Provider>
  );
}

export const useDevolution = () => {
  return useContext(DevolutionContext);
};

export default DevolutionProvider;
