import {
  ThemeProvider,
  LayoutContextProvider,
  SnackBar,
} from '@grupo-sbf/motriz-nike';
import type { NextPageWithLayout } from 'next';
import { SessionProvider } from 'next-auth/react';
import type { AppProps as NextAppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppSeo from '@/src/common/components/AppSeo';
import { GlobalStyle } from '@/src/common/components/GlobalStyle';
import GtmScripts from '@/src/common/components/GtmScripts';
import { getCoreRoutes } from '@/src/common/config/routes';
import DevolutionProvider from '@/src/common/contexts/DevolutionContext/DevolutionContext';
import RequestProvider from '@/src/common/contexts/RequestContext/RequestContext';
import WebsiteUiProvider from '@/src/common/contexts/WebsiteUIContext/WebsiteUiContext';
import { composer } from '@/src/common/utils/composer';
import { trackingPageView } from '@/src/common/utils/tracking';
import MainLayout from '@/src/layout/MainLayout';

type MyAppPropsWithLayout = NextAppProps & {
  Component: NextPageWithLayout;
};

const AppProvider = composer(
  WebsiteUiProvider,
  DevolutionProvider,
  RequestProvider,
  LayoutContextProvider,
);

const gtmID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER || '';

const getRouteName = (url: string) => {
  const routes = getCoreRoutes();
  return Object.keys(routes)[Object.values(routes).indexOf(url)];
};

function MyApp({ Component, pageProps }: MyAppPropsWithLayout) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const mainLayoutProps = Component.mainLayoutProps;

  const getLayout =
    Component.getLayout ||
    ((page) => <MainLayout {...mainLayoutProps}>{page}</MainLayout>);

  const componentLayout = getLayout(<Component {...pageProps} />);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const routeName = getRouteName(url);
      const pageView = {
        url,
        route_name: routeName,
      };
      trackingPageView(pageView);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <SessionProvider
      session={pageProps.session}
      refetchInterval={0}
      refetchOnWindowFocus={false}
    >
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <ThemeProvider>
            <GtmScripts gtmID={gtmID} />
            <AppSeo />
            <GlobalStyle />
            <SnackBar />
            {componentLayout}
          </ThemeProvider>
        </AppProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default MyApp;
