import { ReactNode, createContext, useContext, useState } from 'react';

type ObjectData = {
  id: string;
  orderId: string;
  productSku: string;
  exchangedDate: string;
  reason: number;
  protocol: string;
  returnType: string;
  status: number;
  orbiumRequest: string;
  numberOfProducts: number;
  storeId: string;
  deliveryGroupId: string;
  customerCpfCnpj: string;
  comments: string;
  dimensionHeight: number;
  dimensionWidth: number;
  dimensionDepth: number;
  dimensionWeight: number;
  modelDescription: string;
  colorDescription: string;
  unitaryValue: number;
  totalValue: number;
  freightUnitaryValue: number;
  freightTotalValue: number;
  exchangeLocation: string;
  chargebackType: string;
  descriptionOfReason: string;
  exchangeVoucherId: string;
  orbiumId: string;
  salesChannel: string;
  situation: string;
  customerName: string;
  chargebackBankAccount: object;
  tagCode: string;
};

type RequestContextData = {
  requestValues: ObjectData;
  setRequestPartialValues: (values: Partial<ObjectData>) => void;
};

export type RequestProviderProps = {
  children: ReactNode;
};

export const initialState = {
  id: '',
  orderId: '',
  productSku: '',
  exchangedDate: '',
  reason: 0,
  protocol: '',
  returnType: '',
  status: 0,
  orbiumRequest: '',
  numberOfProducts: 0,
  storeId: '',
  deliveryGroupId: '',
  customerCpfCnpj: '',
  comments: '',
  dimensionHeight: 0,
  dimensionWidth: 0,
  dimensionDepth: 0,
  dimensionWeight: 0,
  modelDescription: '',
  colorDescription: '',
  unitaryValue: 0,
  totalValue: 0,
  freightUnitaryValue: 0,
  freightTotalValue: 0,
  exchangeLocation: '',
  chargebackType: '',
  descriptionOfReason: '',
  exchangeVoucherId: '',
  orbiumId: '',
  salesChannel: '',
  situation: '',
  customerName: '',
  chargebackBankAccount: {},
  tagCode: '',
};

export const RequestContext = createContext({
  requestValues: initialState,
} as RequestContextData);

function RequestProvider({ children }: RequestProviderProps) {
  const [requestValues, setRequestValues] = useState(initialState);
  const setRequestPartialValues: RequestContextData['setRequestPartialValues'] =
    (values) => {
      setRequestValues({ ...requestValues, ...values });
    };

  return (
    <RequestContext.Provider
      value={{
        requestValues,
        setRequestPartialValues,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
}

export const useRequest = () => {
  return useContext(RequestContext);
};

export default RequestProvider;
