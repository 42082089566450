import { UnknownObject } from '@/src/common/types';
import { environment } from '@/src/common/utils';

/**
 * This function abstract the data layer calls
 *
 * @param prop information to populate the data layer
 */
export const push = (prop: unknown) => {
  window.dataLayer.push(prop);
};

/**
 * This function pushed the page information in data layer
 *
 * @param pageName
 * @param prop information to populate the data layer
 */
export const pushPageInfos = (pageName: string, prop: unknown) => {
  push({
    [pageName]: prop,
  });
};

export function sendEventGtm(event: UnknownObject) {
  const hasWillNotHaveDataLayerDefined =
    environment.isServerSide() && !environment.isTestEnv;
  if (hasWillNotHaveDataLayerDefined) {
    console.error('[GTM] dataLayer is undefined');
    return;
  }

  window.dataLayer = window.dataLayer || [];
  push(event);
}
