import { ArrowLeft } from '@grupo-sbf/motriz-icons';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const SubHeader = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: ${theme.spacing[600]};
      border-bottom: ${theme.borderWidth.xthin} solid
        ${theme.color.neutral[300]};
      padding: ${theme.spacing[0]} ${theme.spacing[200]};
    `;
  }}
`;

export const LinksBox = styled.div`
  display: flex;
  align-items: center;
`;

export const SubHeaderIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: end;

    svg {
      font-size: ${theme.fontSize.small};
      margin-right: ${theme.spacing[100]};
      opacity: ${theme.opacity[600]};
    }
  `}
`;

export const HeaderBox = styled.header`
  ${({ theme }) => css`
    width: 100%;
    border-bottom: ${theme.borderWidth.xthin} solid ${theme.color.neutral[300]};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: grid;
    grid-template-columns: 64px 1fr 64px;
    height: ${theme.spacing[800]};
    text-align: center;

    ${media.greaterThan('large')} {
      grid-template-columns: 1fr;
      display: grid;
      height: ${theme.spacing[1000]};
      justify-content: flex-start;
    }

    a {
      ${media.lessThan('large')} {
        grid-column: 2;
      }
    }
  `}
`;

export const ReturnIcon = styled(ArrowLeft)`
  font-size: 30px;

  ${media.greaterThan('large')} {
    display: none;
  }
`;

export const BoxIcon = styled.div`
  ${media.greaterThan('large')} {
    display: none;
  }
`;
