import { ReactNode, createContext, useContext, useState } from 'react';

type ObjectData = {
  initializedApplication: boolean;
};

type WebsiteUiContextData = {
  websiteUiValues: ObjectData;
  setWebsiteUiPartialValues: (values: Partial<ObjectData>) => void;
};

export type WebsiteUiProviderProps = {
  children: ReactNode;
};

export const initialState = {
  initializedApplication: false,
};

export const WebsiteUiContext = createContext({
  websiteUiValues: initialState,
} as WebsiteUiContextData);

function WebsiteUiProvider({ children }: WebsiteUiProviderProps) {
  const [websiteUiValues, setWebsiteUiValues] = useState(initialState);
  const setWebsiteUiPartialValues: WebsiteUiContextData['setWebsiteUiPartialValues'] =
    (values) => {
      setWebsiteUiValues({ ...websiteUiValues, ...values });
    };

  return (
    <WebsiteUiContext.Provider
      value={{
        websiteUiValues,
        setWebsiteUiPartialValues,
      }}
    >
      {children}
    </WebsiteUiContext.Provider>
  );
}

export const useWebsiteUi = () => {
  return useContext(WebsiteUiContext);
};

export default WebsiteUiProvider;
