import styled, { css } from 'styled-components';
import { StyledSectionLinkProps } from './SectionLink.types';

export const SectionLink = styled.a<StyledSectionLinkProps>`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    color: ${theme.color.neutral[100]};
    text-decoration: none;
    outline: none;
    line-height: ${theme.lineHeight.normal};
    text-transform: capitalize;
    cursor: pointer;

    &.focus-visible {
      border-radius: ${theme.borderRadius.medium};
      box-shadow: ${theme.shadow.focus};
    }
  `}
`;
