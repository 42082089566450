import { CorporateContactJsonLd } from 'next-seo';

const CorporateContactJson = () => {
  return (
    <CorporateContactJsonLd
      url="https://autoatendimento.nike.com.br"
      logo="/images/meta/swoosh-logo-black.png"
      contactPoint={[
        {
          telephone: '+55 (11) 4935-2323',
          contactType: 'Fale conosco',
          areaServed: 'BR',
          availableLanguage: ['Brazilian Portuguese'],
        },
      ]}
    />
  );
};

export default CorporateContactJson;
