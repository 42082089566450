/* eslint-disable unicorn/no-array-reduce */
import { ReactNode } from 'react';

export type ComponentWithChildren = (props: {
  children: ReactNode;
}) => JSX.Element;

export const composer = (...providers: ComponentWithChildren[]) => {
  const ComposedComponents: ComponentWithChildren = ({ children }) => {
    return (
      <>
        {providers.reduceRight(
          (child, Provider) => (
            <Provider>{child}</Provider>
          ),
          children,
        )}
      </>
    );
  };

  return ComposedComponents;
};
