import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { StyledContainer, Wrapper } from './Container.styled';

interface ContainerProps extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
  maxWidth?: string;
  fullHeight?: boolean;
  as?: React.ElementType;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, maxWidth, fullHeight = false, as = 'div' }, ref) => (
    <StyledContainer
      fullHeight={fullHeight}
      as={as}
      ref={ref}
      data-testid="container"
    >
      <Wrapper maxWidth={maxWidth}>{children}</Wrapper>
    </StyledContainer>
  ),
);

Container.displayName = 'Container';

export default Container;
