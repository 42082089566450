import { Link as Anchor } from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import { memo } from 'react';
import Container from '@/src/common/components/Container';
import { getCoreRoutes } from '@/src/common/config';
import {
  Copyright,
  CustomDivider,
  Container as FooterContainer,
  MainFooter,
  StyledSectionLink,
  SubFooter,
  SubFooterLinks,
  VerticalGrid,
  Wrapper,
} from './Footer.styled';
import CollapsibleLinks from './components/CollapsibleLinks';
import Payments from './components/Payments';
import Social from './components/Social';
import { FOOTER_LINKS } from './constants';

const PRIVACY_POLICY = 'Política de Privacidade';
const TERMS_OF_SERVICE = 'Termos de Uso';

const coreRoutes = getCoreRoutes();
export const getYear = () => new Date().getFullYear();

const Footer = () => {
  const year = getYear();

  return (
    <FooterContainer data-testid="footer">
      <Container>
        <Wrapper>
          <MainFooter>
            <VerticalGrid size="large">
              <StyledSectionLink
                type="secondary"
                text="Cadastre-se para receber novidades"
                href={coreRoutes.communicationPreference}
              />
            </VerticalGrid>

            <CollapsibleLinks
              id="footer-help"
              title="Ajuda"
              links={FOOTER_LINKS.help}
            />

            <CollapsibleLinks
              id="footer-about"
              title="Sobre a Nike"
              links={FOOTER_LINKS.about}
            />

            <div>
              <Social />

              <Payments />
            </div>
          </MainFooter>

          <CustomDivider />

          <SubFooter>
            <SubFooterLinks>
              <Link href={coreRoutes.geoSelectionLanguage} passHref>
                <Anchor size="small"> Brasil </Anchor>
              </Link>

              <Link href={coreRoutes.privacyPolicy} passHref>
                <Anchor size="small">{PRIVACY_POLICY}</Anchor>
              </Link>

              <Link href={coreRoutes.termsOfService} passHref>
                <Anchor size="small">{TERMS_OF_SERVICE}</Anchor>
              </Link>
            </SubFooterLinks>

            <Copyright>
              © {year} Nike. Todos os direitos reservados. Fisia Comércio de
              Produtos Esportivos Ltda - CNPJ: 59.546.515/0045-55 Rodovia Fernão
              Dias, S/N Km 947.5 - Galpão Modulo 3640 - CEP 37640-903 - Extrema
              - MG
            </Copyright>
          </SubFooter>
        </Wrapper>
      </Container>
    </FooterContainer>
  );
};

export default memo(Footer);
