import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const SocialWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing[400]};

    ${media.greaterThan('large')} {
      margin-top: ${theme.spacing[0]};
    }
  `}
`;

export const SocialGrid = styled.div`
  ${({ theme }) => css`
    width: 200px;
    display: grid;
    grid-gap: ${theme.spacing[200]};
    grid-auto-flow: column;
    justify-content: start;

    svg {
      font-size: ${theme.fontSize.large};
      color ${theme.color.neutral[100]};
      opacity: ${theme.opacity[600]};

      :hover {
        opacity: ${theme.opacity[1000]};
      }
    }
  `}
`;
