import { DefaultSeo } from 'next-seo';

const InitialSeo = () => {
  return (
    <DefaultSeo
      dangerouslySetAllPagesToNoIndex={true}
      dangerouslySetAllPagesToNoFollow={true}
      defaultTitle="Autoatendimento - Nike.com"
      facebook={{
        appId: 'gnj8hiadnrt55wvc1hk8rgvleq2oze',
      }}
      openGraph={{
        type: 'website',
        locale: 'pt_BR',
        site_name: 'autoatendimento.nike.com',
      }}
      twitter={{
        site: '@nike',
        handle: '@nike',
        cardType: 'summary',
      }}
      additionalMetaTags={[
        {
          property: 'google-site-verification',
          content: 'gi_pRov4eUTUF5SblspLUSEncI2Jivqb474BhR6jkFk',
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff',
        },
        {
          name: 'msapplication-TileImage',
          content: '/images/meta/ms-icon-144x144.png',
        },
        {
          name: 'msapplication-config',
          content: '/images/meta/browserconfig.xml',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ]}
      additionalLinkTags={[
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: '/images/meta/apple-icon-57x57.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: '/images/meta/apple-icon-60x60.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: '/images/meta/apple-icon-72x72.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: '/images/meta/apple-icon-76x76.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: '/images/meta/apple-icon-114x114.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: '/images/meta/apple-icon-120x120.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: '/images/meta/apple-icon-144x144.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/images/meta/apple-icon-152x152.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/images/meta/apple-icon-180x180.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '192x192',
          href: '/images/meta/android-icon-192x192.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/images/meta/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: '/images/meta/favicon-96x96.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/images/meta/favicon-16x16.png',
        },
        {
          rel: 'manifest',
          href: '/images/meta/manifest.json',
        },
      ]}
    />
  );
};

export default InitialSeo;
